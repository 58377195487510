.contact-container {
  padding: 40px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10%;
  overflow-y: auto;

  animation-name: changeContent;
  animation-duration: 1s;
  animation-timing-function: linear;

  @keyframes changeContent {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 0;
    }

    75% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }

  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }

  .contact-title {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    h1 {
      font-size: 120px;
      font-weight: 900;
      z-index: 1;
      color: #212121;
      user-select: none;
      letter-spacing: 8px;
    }

    .contact-front-title {
      display: flex;
      gap: 8px;
      z-index: 2;
      position: absolute;

      h2 {
        font-size: 60px;
        font-weight: 900;
      }

      .colored-title {
        color: #ffb400;
      }
    }
  }

  .contact-body {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 100px;
    width: 100%;

    .contact-text-container {
      width: 20%;
      display: flex;
      flex-direction: column;
      gap: 16px;

      h2 {
        font-size: 28px;
        white-space: nowrap;
      }

      .contact-details-container {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .contact-details-item {
          display: flex;
          gap: 16px;

          svg {
            width: 40px;
            height: 40px;
            color: #ffb400;
          }

          .contact-details-text {
            display: flex;
            flex-direction: column;

            .bold {
              color: #ffffff;
              font-weight: bold;
              white-space: nowrap;
            }
            .top {
              color: #a9a9a9;
            }
          }
        }
      }

      .contact-details-buttons {
        display: flex;
        gap: 8px;

        a {
          text-decoration: none;

          svg {
            color: #ffffff;
            width: 40px;
            height: 40px;
            background-color: #252525;
            padding: 6px;
            border-radius: 32px;

            &:hover {
              background-color: #ffb400;
              transition: 0.5s;
            }
          }
        }
      }
    }

    .contact-form-container {
      display: flex;
      flex-direction: column;
      gap: 32px;

      .contact-inputs {
        display: flex;
        gap: 16px;

        input {
          height: 48px;
          width: 13vw;
        }
      }

      .contact-textarea {
        textarea {
          padding-top: 16px;
          width: 41vw;
          height: 200px;
          resize: vertical;
        }
      }

      .contact-button {
        button {
          border: 1px solid #ffb400;
          border-radius: 25px;
          background-color: #2b2a2a;
          color: #ffffff;
          height: 48px;
          padding: 0 16px;
          font-size: 18px;
          display: flex;
          gap: 16px;
          align-items: center;
          cursor: pointer;
          transition: 0.3s;

          &:hover {
            background-color: #ffb400;
          }
        }
      }
    }
  }

  .contact-message {
    font-size: 28px;
    color: #ffb400;

    @media screen and (max-width: 1100px) {
      font-size: 24px;
    }

    @media screen and (max-width: 760px) {
      font-size: 20px;
      width: 80%;
      text-align: center;
    }
  }
}

@media screen and (max-width: 991px) {
  .contact-container {
    padding: 10px 0;
    gap: 5%;

    .contact-body {
      flex-direction: column;
      align-items: center;
      gap: 40px;

      .contact-text-container {
        width: 80%;
      }

      .contact-form-container {
        width: 80%;

        .contact-inputs {
          input {
            width: 33%;
          }
        }

        .contact-textarea {
          textarea {
            width: 100%;
          }
        }

        .contact-button {
          button {
            border-radius: 25px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .contact-container {
    gap: 2.5%;

    .contact-title {
      h1 {
        visibility: hidden;
      }
    }

    .contact-form-container {
      .contact-inputs {
        flex-direction: column;

        input {
          width: 100% !important;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .contact-container {
    margin-top: 48px;

    .contact-title {
      h1 {
        display: none;
      }

      .contact-front-title {
        position: fixed;
        background-color: #2b2a2a;
        width: 100%;
        top: 0;
        padding: 4px 16px;

        h2 {
          font-size: 32px;
        }
      }
    }
    .contact-body {
      gap: 16px;

      .contact-text-container {
        h2 {
          font-size: 22px;
        }
      }
    }
  }
}
