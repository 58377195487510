@media screen and (min-width: 992px) {
  .button-container {
    position: relative;
    user-select: none;

    &:hover {
      div {
        background-color: #ffb400;
        width: 120px;
        transition: 0.3s ease-in-out;

        span {
          opacity: 1;
          transition: 0.3s ease-in-out;
        }
      }
      button {
        background-color: #ffb400;
      }
    }

    button {
      border: none;
      display: flex;
      align-items: center;
      cursor: pointer;
      height: 48px;
      width: 48px;
      border-radius: 50%;
      padding: 0 16px;
      gap: 16px;
      font-size: 18px;
      background-color: #2b2a2a;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.1s ease-in-out;

      &:hover {
        background-color: #ffb400;
      }

      svg {
        z-index: 2;
      }

      .button-mobile {
        display: none;
      }
    }

    .expanded-side {
      width: 0;
      transition: 0.1s ease-in-out;
      position: absolute;
      right: 28px;
      top: 0;
      height: 100%;
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      span {
        opacity: 0;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .button-container {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #333333;
    cursor: pointer;

    button {
      border: none;
      border-radius: 0;
      background: transparent;
      color: #ffffff;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 16px;

      svg {
        width: 32px;
        height: 32px;
      }

      span {
        font-size: 20px;
      }
    }

    .expanded-side {
      display: none;
    }
  }
}
