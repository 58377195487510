input,
textarea {
  background-color: #252525;
  border: none;
  color: #ffffff;
  border-radius: 25px;
  padding: 0 16px;
  font-size: 16px;

  &:focus {
    border: 1px solid #ffb400;
    outline: none;
  }

  &::placeholder {
    color: #757575;
  }
}
