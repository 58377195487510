.portfolio-container {
  padding: 40px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10%;
  overflow-y: auto;

  animation-name: changeContent;
  animation-duration: 1s;
  animation-timing-function: linear;

  @keyframes changeContent {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 0;
    }

    75% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }

  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }

  .portfolio-title {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    h1 {
      font-size: 120px;
      font-weight: 900;
      z-index: 1;
      color: #212121;
      user-select: none;
      letter-spacing: 16px;
    }

    .portfolio-front-title {
      display: flex;
      gap: 8px;
      z-index: 2;
      position: absolute;

      h2 {
        font-size: 60px;
        font-weight: 900;
      }

      .colored-title {
        color: #ffb400;
      }
    }
  }

  .portfolio-projects {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;

    .project-card-container {
      .project-image {
        width: 400px;
        max-width: 30vw;
        height: 300px;
        max-height: 25vh;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        cursor: pointer;
        border-radius: 16px;

        h1 {
          transition: 0.3s ease-out;
          height: 0;
          max-height: 25vh;
          width: 400px;
          max-width: 30vw;
          padding-top: 16px;
          opacity: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &:hover {
          h1 {
            border-radius: 16px;
            height: 300px;
            max-height: 25vh;
            width: 400px;
            max-width: 30vw;
            background-color: #ffb400;
            transition: height 0.3s ease-out;
            opacity: 1;
          }
        }
      }
    }
  }

  .modal-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      position: absolute;
      right: 8px;
      top: 8px;
      background: transparent;
      border: none;

      svg {
        width: 48px;
        height: 48px;
        color: #adadad;
        transition: 0.5s;

        &:hover {
          color: #ffffff;
        }
      }
    }

    .modal-container {
      background-color: #212121;
      padding: 16px;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: center;

      h1 {
        text-align: center;
        color: #ffb400;
      }

      .modal-details {
        display: flex;
        width: 100%;
        justify-content: space-around;

        .modal-row {
          display: flex;
          flex-direction: column;
          gap: 8px;

          .modal-item {
            display: flex;
            gap: 8px;
            align-items: center;

            svg {
              color: lightgray;
            }

            h3 {
              font-size: 16px;
              color: #ffffff;

              a {
                user-select: none;
                color: #ffb400;
              }
            }

            span {
              display: flex;
              gap: 4px;
              align-items: center;
              color: lightgray;

              h3 {
                font-size: 16px;
                color: #ffffff;

                a {
                  color: #ffb400;
                }
              }
            }
          }
        }
      }

      .modal-image {
        width: 500px;
        height: 350px;
        margin: 16px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 8px;
      }
    }
  }
}

@media screen and (max-width: 1460px) {
  .portfolio-container {
    padding: 0 8px;
    gap: 5%;

    .portfolio-projects {
      .project-card-container {
        .project-image {
          width: 300px;
          height: 200px;

          h1 {
            width: 300px;
          }
          &:hover {
            h1 {
              width: 300px;
              height: 200px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1160px) {
  .portfolio-container {
    .portfolio-projects {
      .project-card-container {
        .project-image {
          width: 245px;
          height: 175px;

          h1 {
            width: 245px;
          }
          &:hover {
            h1 {
              width: 245px;
              height: 175px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .portfolio-container {
    padding: 20px 0;
    .portfolio-projects {
      grid-template-columns: 1fr 1fr;

      .project-card-container {
        .project-image {
          width: 400px;
          max-width: 50vw;
          height: 300px;
          max-height: 40vh;

          h1 {
            width: 400px;
            max-width: 50vw;
          }
          &:hover {
            h1 {
              width: 400px;
              max-width: 50vw;
              height: 300px;
              max-height: 40vh;
            }
          }
        }
      }
    }

    .modal-background {
      background-color: #212121;
      z-index: 11;

      button {
        top: 1%;
        right: 50%;
        transform: translateX(50%);
      }
    }
  }
}

@media screen and (max-width: 840px) {
  .portfolio-container {
    .portfolio-projects {
      grid-template-columns: 1fr 1fr;

      .project-card-container {
        .project-image {
          width: 350px;
          max-width: 50vw;
          height: 225px;

          h1 {
            width: 350px;
            max-width: 50vw;
          }
          &:hover {
            h1 {
              width: 350px;
              max-width: 50vw;
              height: 225px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 740px) {
  .portfolio-container {
    .portfolio-projects {
      grid-template-columns: 1fr 1fr;

      .project-card-container {
        .project-image {
          width: 300px;
          max-width: 50vw;
          height: 200px;

          h1 {
            width: 300px;
            max-width: 50vw;
          }
          &:hover {
            h1 {
              width: 300px;
              max-width: 50vw;
              height: 200px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .portfolio-container {
    .portfolio-projects {
      grid-template-columns: 1fr 1fr;

      .project-card-container {
        .project-image {
          width: 250px;
          max-width: 50vw;
          height: 175px;

          h1 {
            width: 250px;
            max-width: 50vw;
          }
          &:hover {
            h1 {
              width: 250px;
              max-width: 50vw;
              height: 175px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .portfolio-container {
    .portfolio-title {
      h1 {
        display: none;
      }

      .portfolio-front-title {
        position: fixed;
        background-color: #2b2a2a;
        width: 100%;
        top: 0;
        padding: 4px 16px;

        h2 {
          font-size: 32px;

          @media screen and (max-width: 330px) {
            font-size: 28px;
          }
        }
      }
    }
    .portfolio-projects {
      grid-template-columns: 1fr;

      .project-card-container {
        .project-image {
          width: 500px;
          max-width: 90vw;
          height: 300px;
          max-height: 60vh;

          h1 {
            width: 500px;
            max-width: 90vw;
          }
          &:hover {
            h1 {
              width: 500px;
              max-width: 90vw;
              height: 300px;
              max-height: 60vh;
            }
          }

          @media screen and (max-width: 476px) {
            height: 250px;

            &:hover {
              h1 {
                height: 250px;
              }
            }
          }

          @media screen and (max-width: 376px) {
            height: 200px;

            &:hover {
              h1 {
                height: 200px;
              }
            }
          }
        }
      }
    }

    .modal-background {
      .modal-container {
        .modal-details {
          flex-direction: column;
          gap: 8px;

          .modal-item,
          span,
          h3,
          a {
            font-size: 14px !important;
          }
        }

        .modal-image {
          width: 500px;
          max-width: 90vw;
          height: 300px;
          max-height: 60vh;

          @media screen and (max-width: 476px) {
            height: 250px;
          }

          @media screen and (max-width: 350px) {
            height: 200px;
          }
        }
      }
    }
  }
}

@media (orientation: landscape) and (max-width: 991px) {
  .portfolio-container {
    .portfolio-projects {
      .project-card-container {
        .project-image {
          height: 300px;
          max-height: 60vh;

          &:hover {
            h1 {
              max-height: 60vh;
              height: 300px;
            }
          }
        }
      }
    }

    .modal-background {
      overflow-y: auto;

      .modal-container {
        margin-top: 250px;
      }
    }
  }
}
