.sidemenu-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 32px;
  transition: width 0.3s;

  .sidemenu-buttons {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .active {
    background-color: #ffb400;
  }
}

@media screen and (max-width: 991px) {
  .sidemenu-container {
    display: flex;
    position: absolute;
    justify-content: flex-start;
    padding: 32px 0;
    transition: width 0.3s;
    overflow-y: auto;

    &.open {
      padding-top: 16px;
      padding-left: 16px;
      padding-right: 16px;
      background-color: #2b2a2a;
      width: 100vw;
      height: 100%;
      transition: width 0.3;
      z-index: 8;

      .sidemenu-buttons {
        display: flex;
        gap: 32px;

        .active {
          color: #ffb400;
          background: transparent;
        }
      }
    }

    &.close {
      width: 0;
      height: 100%;
      transition: width 0.3s;
      background-color: #2b2a2a;

      .sidemenu-buttons {
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s;
      }
    }
  }
}

@media (orientation: landscape) and (max-width: 991px) {
  .sidemenu-container {
    padding-top: 16px;

    .sidemenu-buttons {
      gap: 16px;
      overflow-y: hidden;
    }
  }
}
