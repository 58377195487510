.app-container {
  background-color: #111111;
  color: #ffffff;
  width: 100vw;
  height: 100dvh;

  display: flex;
  justify-content: space-between;
  position: relative;
  overflow-y: hidden;

  .mobile-button {
    display: none;
  }

  @media screen and (max-width: 991px) {
    .mobile-button {
      display: flex;
      right: 16px;
      top: 16px;
      position: absolute;
      z-index: 9;

      button {
        border: none;
        padding: 8px;
        background-color: #2b2a2a;
        color: #ffffff;
        cursor: pointer;
        border-radius: 8px;
      }
    }
  }

  @keyframes changeTab {
    0% {
      height: 0;
      width: 100%;
    }

    50% {
      height: 100%;
      width: 100%;
    }

    100% {
      height: 0;
      width: 100%;
    }
  }

  .animation-dark {
    animation-name: changeTab;
    animation-duration: 1s;
    // animation-timing-function: ease-in-out;
    animation-iteration-count: 1;

    background-color: #252525; // change color ?
    position: absolute;
    z-index: 10;
  }

  .animation-dark-second {
    animation-name: changeTab;
    animation-duration: 1s;
    // animation-timing-function: ease-in-out;
    animation-delay: 0.5s;
    animation-iteration-count: 1;

    background-color: #252525; // change color ?
    position: absolute;
    z-index: 11;
  }

  .animation-orange {
    animation-name: changeTab;
    animation-duration: 1s;
    // animation-timing-function: ease-in-out;
    animation-delay: 0.25s;
    animation-iteration-count: 1;

    background-color: #ffb400; // change color ?
    position: absolute;
    z-index: 10;
  }

  .animation-orange-second {
    animation-name: changeTab;
    animation-duration: 1s;
    // animation-timing-function: ease-in-out;
    animation-delay: 0.75s;
    animation-iteration-count: 1;

    background-color: #ffb400; // change color ?
    position: absolute;
    z-index: 11;
  }
}
